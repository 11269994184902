import { Dispatch, SetStateAction } from "react"

export function fileSizeCheck(
  files: FileList,
  setAlertOpen: Dispatch<SetStateAction<boolean>>
): boolean {
  const fileGreaterThan200MB = Array.from(files).some((file) => {
    return file.size > 200 * 1024 * 1024 // Check if file is greater than 200 MB
  })
  if (fileGreaterThan200MB) {
    setAlertOpen(true)
    return false
  }
  return true
}
