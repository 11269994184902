import { PlayArrow, RemoveCircle, Upload } from "@mui/icons-material"
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import {
  Box,
  Checkbox,
  Fab,
  ImageList,
  ImageListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { CHAT_MEDIA_MAX_FILES } from "../../../../utils/constants"
import { LoadingSpinner } from "../../../Atoms/LoadingSpinner"
import { IOrderPromoPhoto } from "../../../Atoms/Promo/AddMedia"
import { FolderDetail } from "./FolderDetail"
import { IFolder, MediaFolders } from "./MediaFolders"

interface IManageMediaProps {
  profileName: string
  setOpenManageMedia: Dispatch<SetStateAction<boolean>>
  folders: IFolder[]
  setFolders: Dispatch<SetStateAction<IFolder[]>>
  selectedPhotos?: IOrderPromoPhoto[]
  setSelectedPhotos?: Dispatch<SetStateAction<IOrderPromoPhoto[]>>
  getProfilePhotos?: () => void
  activeFolder: IFolder | undefined | null
  setActiveFolder: Dispatch<SetStateAction<IFolder | undefined | null>>
  removedSelectedPhoto?: (photo: IOrderPromoPhoto) => void
  placeholderCount: number
  setPlaceholderCount: Dispatch<SetStateAction<number>>
  imgCols?: number
  maxMediaAmount?: number
  openMediaUpload?: () => void
  isEditing: boolean
}

// eslint-disable-next-line complexity
export const ManageMedia = ({
  profileName,
  setOpenManageMedia,
  folders,
  setFolders,
  selectedPhotos = [],
  setSelectedPhotos,
  getProfilePhotos,
  activeFolder,
  setActiveFolder,
  removedSelectedPhoto,
  placeholderCount,
  setPlaceholderCount,
  imgCols = 0,
  maxMediaAmount = CHAT_MEDIA_MAX_FILES,
  openMediaUpload,
  isEditing,
}: IManageMediaProps): JSX.Element => {
  const theme = useTheme()
  const selectedPhotosBox = useRef<HTMLInputElement>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  // Get selected photos to stay fixed to bottom of Popup
  const container = selectedPhotosBox.current?.parentElement?.parentElement
  const [paddingBottom, setPaddingBottom] = useState<number>(8)
  const [selectedBoxBottom, setSelectedBoxBottom] = useState<number>(0)
  const [displaySelected, setDisplaySelected] = useState<boolean>(false)
  const [visibilitySelected, setVisibilitySelected] = useState<boolean>(false)

  useEffect(() => {
    Array.from({ length: 30 }, (_, i) => i).forEach((i) => {
      setTimeout(alignSelectionBox, i * 100)
    })
    setTimeout(() => {
      if (selectedPhotos.length > 0) {
        setVisibilitySelected(true)
      }
    }, 0)
  }, [activeFolder, selectedPhotos])

  const alignSelectionBox = (): void => {
    setDisplaySelected(true)

    setTimeout(() => {
      setPaddingBottom(
        selectedPhotosBox.current
          ? selectedPhotosBox.current?.offsetHeight + 8
          : 8
      )
      setTimeout(() => {
        const parent = selectedPhotosBox.current?.parentElement?.parentElement
        setSelectedBoxBottom(parent ? parent.offsetTop : 0)
      }, 0)
    }, 0)
  }

  if (!isEditing) {
    useEffect(() => {
      if (selectedPhotos.length === 0 && placeholderCount === 0) {
        setDisplaySelected(false)
        setPaddingBottom(8)
        setVisibilitySelected(false)
      }
    }, [selectedPhotos])
  }

  container?.addEventListener(
    "touchmove",
    (e: Event) => {
      e.stopPropagation()
    },
    { passive: true }
  )

  useEffect(() => {
    window.addEventListener("resize", alignSelectionBox)
    return () => {
      window.removeEventListener("resize", alignSelectionBox)
    }
  }, [])

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          margin: "16px 16px 0 16px",
          justifyContent: "space-between",
        }}
      >
        {((activeFolder !== undefined && isEditing) || !isEditing) && (
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              if (activeFolder !== undefined) {
                setActiveFolder(undefined)
              } else {
                setOpenManageMedia(false)
              }
            }}
          >
            <ArrowBackOutlinedIcon />
          </Box>
        )}

        <Typography
          variant={"body1"}
          fontWeight={600}
          sx={{ paddingTop: "2px", flexGrow: "2" }}
        >
          {isEditing ? "Blossm Media" : "Add Media from Blossm"}
        </Typography>

        <CloseOutlinedIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenManageMedia(false)
          }}
        />
      </Box>

      {!isEditing && (
        <Box
          sx={{
            margin: "16px",
          }}
        >
          <Typography variant="body2">
            {`Add Photos, GIFs, and Videos (${selectedPhotos.length}/${maxMediaAmount})`}
          </Typography>
        </Box>
      )}

      {selectedPhotos.length > 0 && (
        <Box
          ref={selectedPhotosBox}
          sx={{
            display: displaySelected ? "block" : "none",
            visibility: visibilitySelected ? "visible" : "hidden",
            backgroundColor: "#ffffff",
            borderRadius: isMobile ? "16px 16px 0 0" : "16px",
            boxShadow: "0 0 32px #aaa",
            bottom: isMobile ? 0 : `${selectedBoxBottom}px`,
            left: `${container ? container.offsetLeft : 0}px`,
            right: `${container ? container.offsetLeft : 0}px`,
            padding: "0 16px",
            position: "fixed",
            zIndex: 9999,
          }}
        >
          <ImageList
            cols={imgCols}
            rowHeight={54}
            gap={8}
            sx={{
              justifyItems: "center",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              overflow: "visible",
            }}
          >
            {Array.from({ length: placeholderCount }, (_, index) => {
              return <LoadingSpinner size={54} key={index} />
            })}
            {selectedPhotos.map(
              (photo) =>
                photo?.originalUrl && (
                  <ImageListItem
                    key={photo.id}
                    sx={{ width: "54px", height: "54px", cursor: "pointer" }}
                    onClick={() => {
                      if (removedSelectedPhoto) {
                        removedSelectedPhoto(photo)
                      }
                    }}
                  >
                    <img
                      className={"image-list-item"}
                      src={photo.thumbnailUrl}
                      style={{
                        width: "54px",
                        height: "54px",
                        borderRadius: "8px",
                        objectFit: "scale-down",
                      }}
                      alt="Media"
                      loading="lazy"
                    />
                    <Checkbox
                      checked={true}
                      checkedIcon={
                        <RemoveCircle
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: "50%",
                          }}
                        />
                      }
                      sx={{
                        position: "absolute",
                        padding: 0,
                        top: "-8px",
                        right: "-8px",
                        ":hover": { background: "unset" },
                      }}
                    />
                    {photo.isVideo && (
                      <PlayArrow
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "#fff",
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: "50%",
                          padding: "4px",
                          fontSize: "40px",
                        }}
                      />
                    )}
                  </ImageListItem>
                )
            )}
          </ImageList>
        </Box>
      )}

      <Box
        sx={{
          padding: `0 8px ${paddingBottom}px 8px`,
        }}
      >
        {activeFolder === undefined && (
          <MediaFolders
            profileName={profileName}
            folders={folders}
            setFolders={setFolders}
            setActiveFolder={setActiveFolder}
            isEditing={isEditing}
          />
        )}
        {(activeFolder || activeFolder === null) && (
          <FolderDetail
            profileName={profileName}
            folder={activeFolder}
            selectedPhotos={selectedPhotos}
            setSelectedPhotos={setSelectedPhotos}
            getProfilePhotos={getProfilePhotos}
            setActiveFolder={setActiveFolder}
            placeholderCount={placeholderCount}
            setPlaceholderCount={setPlaceholderCount}
            isEditing={isEditing}
          />
        )}

        {!isEditing && (
          <Fab
            variant={"extended"}
            color={"primary"}
            sx={{
              display: "flex",
              float: "right",
              margin: "8px",
              position: "sticky",
            }}
            onClick={() => {
              if (openMediaUpload) {
                openMediaUpload()
              }
            }}
          >
            <Upload sx={{ marginRight: "8px" }} />
            Upload New
          </Fab>
        )}
      </Box>
    </>
  )
}
