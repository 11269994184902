import React from "react"
import { Alert, Snackbar } from "@mui/material"
import { AlertColor } from "@mui/material/Alert/Alert"

interface IAlertSnackBar {
  showAlert: boolean
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
  alertType: string
  alertText: string
  origin: "top" | "bottom"
  offset?: number
}

export const AlertSnackBar = ({
  showAlert,
  setShowAlert,
  alertType,
  alertText,
  origin,
  offset = 50,
}: IAlertSnackBar): React.JSX.Element => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: origin,
        horizontal: "center",
      }}
      open={showAlert}
      autoHideDuration={10000}
      onClose={() => {
        setShowAlert(false)
      }}
      sx={{
        marginTop: origin === "top" ? `${offset}px` : 0,
        marginBottom: origin === "bottom" ? `${offset}px` : 0,
      }}
    >
      <Alert severity={alertType as AlertColor}>{alertText}</Alert>
    </Snackbar>
  )
}
