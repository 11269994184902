import { Box, Typography } from "@mui/material"
import { theme } from "../../../../../styles/Theme"
import { IFolder } from "./MediaFolders"
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined"
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined"
import { Dispatch, SetStateAction } from "react"
import Image from "next/legacy/image"
import BlossmLogo from "../../../assets/BlossmLogoSmallWhite.svg"
import { customLoader } from "../../../../utils/imageLoader"

export interface IFolderItem {
  folder: IFolder | undefined | null
  index?: number
  setMenuFolder?: Dispatch<SetStateAction<IFolder | undefined | null>>
  setMenuAnchor?: Dispatch<SetStateAction<HTMLElement | undefined>>
  setActiveFolder?: Dispatch<SetStateAction<IFolder | undefined | null>>
}

// eslint-disable-next-line complexity
export const FolderItem = ({
  folder,
  index = 0,
  setMenuFolder,
  setMenuAnchor,
  setActiveFolder,
}: IFolderItem): JSX.Element => {
  return (
    <>
      {folder && (
        <Box
          key={index}
          sx={{
            display: "flex",
            gap: "0px",
            cursor: setActiveFolder ? "pointer" : "unset",
            justifyContent: "space-between",
            flexBasis: "100%",
            ":hover": !setActiveFolder
              ? ""
              : {
                  color: theme.palette.primary.main,
                },
          }}
          onClick={() => {
            if (setActiveFolder) {
              setActiveFolder(folder)
            }
          }}
        >
          <Box
            sx={{
              display: "block",
              width: "64px",
              height: "64px",
              backgroundColor: theme.palette.secondary.main,
              backgroundImage: `url(${folder.thumbnail})` || undefined,
              backgroundSize: "contain",
              borderRadius: "8px",
              padding: "14.5px 0 0 14.5px",
            }}
          >
            {folder && !folder.thumbnail && (
              <FolderOutlinedIcon fontSize="large" color="primary" />
            )}
          </Box>
          <Box sx={{ flexGrow: 2, margin: "12px 16px", width: "200px" }}>
            <Typography variant={"body1"} fontWeight={600} noWrap>
              {folder ? folder.name : "Blossm Media"}
            </Typography>

            {!folder.photoCount && !folder.videoCount ? (
              <Typography variant={"body1"} fontStyle={"italic"}>
                No media
              </Typography>
            ) : (
              <Typography variant={"body1"}>
                {folder.photoCount
                  ? `${folder.photoCount} Image${
                      folder.photoCount > 1 ? "s" : ""
                    }`
                  : ""}
                {folder.photoCount && folder.videoCount ? ", " : ""}
                {folder.videoCount
                  ? `${folder.videoCount} Video${
                      folder.videoCount > 1 ? "s" : ""
                    }`
                  : ""}
              </Typography>
            )}
          </Box>
          {setMenuFolder && setMenuAnchor && (
            <Box
              sx={{
                display: "block",
                cursor: "pointer",
                paddingTop: "20px",
              }}
              onClick={(e) => {
                e.stopPropagation()
                setMenuFolder(folder)
                setMenuAnchor(e.currentTarget)
              }}
            >
              <MoreVertOutlinedIcon />
            </Box>
          )}
        </Box>
      )}

      {folder === null && (
        <Box
          key={index}
          sx={{
            display: "flex",
            gap: "0px",
            cursor: setActiveFolder ? "pointer" : "unset",
            justifyContent: "space-between",
            flexBasis: "100%",
            color: setActiveFolder ? theme.palette.primary.main : "unset",
            ":hover": !setActiveFolder
              ? ""
              : {
                  color: theme.palette.primary.dark,
                },
          }}
          onClick={() => {
            if (setActiveFolder) {
              setActiveFolder(null)
            }
          }}
        >
          <Box
            sx={{
              display: "block",
              width: "64px",
              height: "64px",
              backgroundColor: theme.palette.primary.main,
              backgroundSize: "contain",
              borderRadius: "8px",
              padding: "15.5px 0 0 16.5px",
            }}
          >
            <Image
              width={32}
              height={32}
              src={BlossmLogo}
              loader={customLoader}
              quality="100"
              priority
              alt="Blossm Logo"
            />
          </Box>
          <Box sx={{ flexGrow: 2, margin: "12px 16px" }}>
            <Typography variant={"body1"} fontWeight={600}>
              Blossm Gallery
            </Typography>

            <Typography variant={"body1"}>Profile Media</Typography>
          </Box>
          {setMenuFolder && setMenuAnchor && (
            <Box
              sx={{
                display: "block",
                width: "64px",
                height: "64px",
                padding: "20px 0 0 40px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation()
                setMenuFolder(null)
                setMenuAnchor(e.currentTarget)
              }}
            >
              <MoreVertOutlinedIcon />
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
