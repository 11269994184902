import { Box, Dialog, Typography } from "@mui/material"
import { InverseButton, ErrorButton } from "../../../Atoms/Buttons"
import { put } from "../../../../utils/requests"
import { Dispatch, SetStateAction, useState } from "react"
import { theme } from "../../../../../styles/Theme"
import { IFolder, parseFolders } from "./MediaFolders"
import { FolderItem } from "./FolderItem"

export interface IDeleteFolderDialog {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setFolders?: Dispatch<SetStateAction<IFolder[]>>
  folder: IFolder | undefined | null
  setPreviousFolder: Dispatch<SetStateAction<IFolder | undefined | null>>
  setActiveFolder: Dispatch<SetStateAction<IFolder | undefined | null>>
}

export const DeleteFolderDialog = ({
  open,
  setOpen,
  setFolders,
  folder,
  setPreviousFolder,
  setActiveFolder,
}: IDeleteFolderDialog): JSX.Element => {
  const [errorText, setErrorText] = useState<string>("")

  const createOrReanmeFolder = (): void => {
    if (folder) {
      put("/photo_video/folder/", { name: folder.name })
        .then((response) => {
          handleClose()
          if (setFolders) {
            setFolders(parseFolders(response.data))
          }
          setActiveFolder(undefined)
        })
        .catch((error) => {
          if (error.response.data) {
            setErrorText(error.response.data)
          } else {
            console.error(error)
          }
        })
    }
  }

  const handleClose = (): void => {
    setOpen(false)
    setErrorText("")
    setPreviousFolder(undefined)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          minWidth: "312px",
        },
      }}
    >
      <Typography variant="h6">Delete Folder</Typography>

      {folder && <FolderItem folder={folder} />}

      <Typography variant="body1">
        Permanently delete this folder and all contents inside from your Blossm
        media?
      </Typography>
      {errorText && (
        <Typography variant="body1" color={theme.palette.error.main}>
          Error: {errorText}
        </Typography>
      )}
      <Box sx={{ display: "flex", gap: "8px", justifyContent: "end" }}>
        <InverseButton variant={"outlined"} onClick={handleClose}>
          Cancel
        </InverseButton>
        <ErrorButton
          onClick={() => {
            setErrorText("")
            createOrReanmeFolder()
          }}
          variant={"contained"}
        >
          Delete
        </ErrorButton>
      </Box>
    </Dialog>
  )
}
