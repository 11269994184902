import { Box, Dialog, Typography } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { theme } from "../../../../../styles/Theme"
import { IFolder } from "./MediaFolders"
import { FolderItem } from "./FolderItem"

export interface IMoveMediaDialog {
  open: boolean
  setMoveFolderOpen: Dispatch<SetStateAction<boolean>>
  handleMove: (folder?: IFolder) => void
  currectFolder: IFolder | undefined | null
  folders: IFolder[]
}

export const MoveMediaDialog = ({
  open,
  setMoveFolderOpen,
  handleMove,
  currectFolder,
  folders,
}: IMoveMediaDialog): JSX.Element => {
  return (
    <Dialog open={open} onClose={() => setMoveFolderOpen(false)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          margin: "16px",
        }}
      >
        <Typography
          variant={"body1"}
          fontWeight={600}
          sx={{ marginBottom: "8px" }}
        >
          Move Media to New Folder
        </Typography>
        <Box
          sx={{
            cursor: currectFolder === null ? "unset" : "pointer",
            color: theme.palette.primary.main,
            ":hover":
              currectFolder === null
                ? ""
                : {
                    color: theme.palette.primary.dark,
                  },
          }}
          onClick={() => {
            if (currectFolder !== null) {
              handleMove()
            }
          }}
        >
          <FolderItem folder={null} index={-1} />
        </Box>
        <hr style={{ margin: "0", borderColor: "rgba(0, 0, 0, 0.12)" }} />
        {folders.map((folder, index) => (
          <Box
            key={index}
            sx={{
              cursor: folder === currectFolder ? "unset" : "pointer",
              color:
                folder === currectFolder ? theme.palette.grey[400] : "unset",
              ":hover":
                folder === currectFolder
                  ? ""
                  : {
                      color: theme.palette.primary.main,
                    },
            }}
            onClick={() => {
              if (folder !== currectFolder) {
                handleMove(folder)
              }
            }}
          >
            <FolderItem folder={folder} index={index} />
          </Box>
        ))}
      </Box>
    </Dialog>
  )
}
