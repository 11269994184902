import {
  Alert,
  Box,
  Checkbox,
  Fab,
  Menu,
  MenuItem,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { BlossmImage } from "../../../Atoms/BlossmImage"
import { PlayArrow, VisibilityOff } from "@mui/icons-material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { IFolder, parseFolders } from "./MediaFolders"
import { FolderItem } from "./FolderItem"
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined"
import { fileSizeCheck } from "../../../../utils/mediaUploadSizeCheck"
import { deleteRequest, get, patch, post } from "../../../../utils/requests"
import { parsePhotos } from "../../../../utils/parsers"
import {
  modalDisplayErrorHandler,
  ProfileUpdate,
} from "../../../../utils/imageErrorModalHandler"
import { ErrorContext } from "../../../../contexts/photoUploadErrors"
import { UserContext } from "../../../../contexts/user"
import { LoadingSpinner } from "../../../Atoms/LoadingSpinner"
import { AlertSnackBar } from "../../../Atoms/AlertSnackBar"
import { FILE_UPLOAD_SIZE_ERROR } from "../../../../utils/constants"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { FolderNameDialog } from "./FolderNameDialog"
import { DeleteFolderDialog } from "./DeleteFolderDialog"
import { ImageViewer } from "../../../Atoms/ImageViewer"
import { Modal } from "../../../Molecules/Modal/Modal"
import { IOrderPromoPhoto } from "../../../Atoms/Promo/AddMedia"
import { MoveMediaDialog } from "./MoveMediaDialog"

interface IFolderDetial {
  profileName: string
  folder: IFolder | undefined | null
  selectedPhotos: IOrderPromoPhoto[]
  setSelectedPhotos?: Dispatch<SetStateAction<IOrderPromoPhoto[]>>
  getProfilePhotos?: () => void
  setActiveFolder: Dispatch<SetStateAction<IFolder | undefined | null>>
  placeholderCount: number
  setPlaceholderCount: Dispatch<SetStateAction<number>>
  isEditing: boolean
}

// eslint-disable-next-line complexity
export const FolderDetail = ({
  profileName,
  folder,
  selectedPhotos,
  setSelectedPhotos,
  getProfilePhotos,
  setActiveFolder,
  placeholderCount,
  setPlaceholderCount,
  isEditing,
}: IFolderDetial): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const photoInputFile = useRef<HTMLInputElement>(null)
  const [warningAlertOpen, setWarningAlertOpen] = useState<boolean>(false)
  const [menuFolder, setMenuFolder] = useState<IFolder | undefined | null>(
    undefined
  )
  const [currentFolder, setCurrentFolder] = useState<
    IFolder | undefined | null
  >(folder)
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | undefined>(
    undefined
  )
  const [contextFolder, setContextFolder] = useState<
    IFolder | undefined | null
  >(undefined)
  const [openFolderNameDialog, setOpenFolderNameDialog] =
    useState<boolean>(false)
  const [openDeleteFolderDialog, setOpenDeleteFolderDialog] =
    useState<boolean>(false)
  const [photos, setPhotos] = useState<IOrderPromoPhoto[]>([])
  const { context } = useContext(UserContext)
  const { errorDispatch } = useContext(ErrorContext)
  const [isSelecting, setIsSelecting] = useState<boolean>(!isEditing)
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([])
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")
  const [imageViewerOpen, setImageViewerOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)
  const [moveFolderOpen, setMoveFolderOpen] = useState(false)
  const [folders, setFolders] = useState<IFolder[]>([])

  useEffect(() => {
    get("/photo_video/folder/")
      .then((response) => {
        setFolders(parseFolders(response.data))
      })
      .catch((error) => console.error(error))
  }, [photos])

  useEffect(() => {
    if (currentFolder) {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].name === currentFolder?.name) {
          setCurrentFolder(folders[i])
        }
      }
    }
  }, [folders])

  useEffect(() => {
    photos.forEach((photo) => {
      if (selectedPhotos.find((media) => media.id === photo.id)) {
        photo.selected = true
      } else {
        photo.selected = false
      }
    })
  }, [selectedPhotos])

  function updatedSelectedPhotos(): void {
    if (setSelectedPhotos) {
      setSelectedPhotos((prevState) => [
        ...prevState.filter(
          (photo) => !photos.find((media) => media.id === photo.id)
        ),
        ...prevState.filter((photo) =>
          photos.find((media) => media.selected && media.id === photo.id)
        ),
        ...photos.filter(
          (photo) =>
            photo.selected && !prevState.find((media) => media.id === photo.id)
        ),
      ])
    }
  }

  if (!isEditing) {
    useEffect(updatedSelectedPhotos, [photos])
  }

  const [isGettingPhotos, setIsGettingPhotos] = useState<boolean>(false)

  function getPhotos(): void {
    if (isGettingPhotos) {
      return
    }

    setIsGettingPhotos(true)

    get(
      `/photo_video/photo/list/${profileName}/${
        currentFolder ? currentFolder.name + "/" : ""
      }`
    )
      .then((response) => {
        const photos = parsePhotos(response.data)
        setPhotos(photos)
        setPlaceholderCount(0)

        photos.forEach((photo) => {
          if (selectedPhotos.find((media) => media.id === photo.id)) {
            photo.selected = true
          }
        })

        setIsGettingPhotos(false)
      })
      .catch((error) => console.error(error))
  }

  function onMediaDelete(): void {
    getPhotos()
    setImageViewerOpen(false)
    setSnackbarMessage("Media deleted!")
    if (getProfilePhotos) {
      getProfilePhotos()
    }
  }

  function onMediaUpdate(message: string): void {
    getPhotos()
    setSnackbarMessage(message)
    if (getProfilePhotos) {
      getProfilePhotos()
    }
  }

  function handlePhotoSelection(index: number): void {
    setCurrentImageIndex(index)
    setImageViewerOpen(true)
  }

  function toggleSelectionForIndex(index: number): void {
    if (selectedIndexes.indexOf(index) >= 0) {
      setSelectedIndexes((prevArray) => prevArray.filter((i) => i !== index))
    } else {
      setSelectedIndexes((prevArray) => [...prevArray, index])
    }
  }

  function makeSelectedPrivate(isPrivate: boolean): void {
    const patches = []
    const formData = new FormData()
    formData.append("is_public", isPrivate ? "False" : "True")
    for (let i = 0; i < selectedIndexes.length; i++) {
      patches.push(
        patch(
          `/photo_video/photo/update/${photos[selectedIndexes[i]].id}/`,
          formData
        )
      )
    }
    Promise.all(patches).then(() => {
      getPhotos()
      setSnackbarMessage(`Media set to ${isPrivate ? "private" : "public"}`)
      setIsSelecting(false)
      setSelectedIndexes([])
      if (getProfilePhotos) {
        getProfilePhotos()
      }
    })
  }

  function openMoveDialog(): void {
    get("/photo_video/folder/")
      .then((response) => {
        setFolders(parseFolders(response.data))
        setMoveFolderOpen(true)
      })
      .catch((error) => console.error(error))
  }

  function moveSelected(folder?: IFolder): void {
    const patches = []
    const formData = new FormData()
    formData.append("folder_name", folder ? folder.name : "")
    const indexes = selectedIndexes
    if (indexes.length === 0) {
      indexes.push(currentImageIndex)
    }
    for (let i = 0; i < selectedIndexes.length; i++) {
      patches.push(
        patch(
          `/photo_video/photo/move/${photos[selectedIndexes[i]].id}/`,
          formData
        )
      )
    }
    Promise.all(patches).then(() => {
      getPhotos()
      if (getProfilePhotos) {
        getProfilePhotos()
      }
      setSnackbarMessage(
        `Media moved to ${folder ? folder.name : "Blossm Gallery"}`
      )
      setIsSelecting(false)
      setSelectedIndexes([])
      setMoveFolderOpen(false)
    })
  }

  function deleteSelected(): void {
    const deleteRequests = []
    for (let i = 0; i < selectedIndexes.length; i++) {
      deleteRequests.push(
        deleteRequest(
          `/photo_video/photo/delete/${photos[selectedIndexes[i]].id}/`
        )
      )
    }
    Promise.all(deleteRequests).then(() => {
      getPhotos()
      setSnackbarMessage("Media deleted")
      setIsSelecting(false)
      setSelectedIndexes([])
      setDeleteModal(false)
      if (getProfilePhotos) {
        getProfilePhotos()
      }
    })
  }

  useEffect(() => {
    getPhotos()
  }, [profileName])

  return (
    <Box sx={{ padding: "8px" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          color: currentFolder === null ? theme.palette.primary.main : "unset",
        }}
      >
        {(currentFolder || currentFolder === null) && (
          <FolderItem
            folder={currentFolder}
            setMenuFolder={setMenuFolder}
            setMenuAnchor={setMenuAnchor}
          />
        )}
      </Box>
      <hr style={{ margin: "16px 0", borderColor: "rgba(0, 0, 0, 0.12)" }} />
      <Box
        sx={{
          paddingBottom: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "8px",
          width: "100%",
          maxHeight: isEditing
            ? `${isMobile ? "calc(100vh - 328px)" : "calc(90vh - 280px)"}`
            : `${isMobile ? "calc(100vh - 376px)" : "calc(90vh - 366px)"}`,
          overflowY: "auto",
          marginBottom: isEditing ? "16px" : "0",
        }}
      >
        {Array.from({ length: placeholderCount }, (_, index) => {
          return <LoadingSpinner size={109} key={index} />
        })}
        {photos.map(
          (photo, index) =>
            photo.thumbnailUrl && (
              <Box
                sx={{
                  height: "109px",
                  width: "109px",
                  position: "relative",
                  cursor: "pointer",
                }}
                key={index}
                onClick={() => {
                  if (isEditing) {
                    if (!isSelecting) {
                      handlePhotoSelection(index)
                    } else {
                      toggleSelectionForIndex(index)
                    }
                  } else {
                    photo.selected = !photo.selected
                    updatedSelectedPhotos()
                  }
                }}
              >
                <BlossmImage
                  alt={"thumbnail url"}
                  src={photo.thumbnailUrl}
                  borderRadius={8}
                />

                {isSelecting && (
                  <Checkbox
                    checked={
                      isEditing
                        ? selectedIndexes.indexOf(index) >= 0
                        : photo.selected
                    }
                    icon={
                      <RadioButtonUncheckedIcon
                        sx={{
                          color: "#fff",
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                          borderRadius: "50%",
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{ backgroundColor: "#fff", borderRadius: "50%" }}
                      />
                    }
                    sx={{
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      ":hover": { background: "unset" },
                    }}
                  />
                )}

                {photo.isVideo && (
                  <Fab
                    size="medium"
                    color="primary"
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      cursor: isSelecting ? "default" : "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.9)",
                      },
                    }}
                  >
                    <PlayArrow
                      sx={{
                        color: "rgba(255, 255, 255, 0.7)",
                        fontSize: "30px",
                      }}
                    />
                  </Fab>
                )}

                {currentFolder === null && !photo.isPublic && (
                  <Tooltip title="Only visible to you" arrow>
                    <VisibilityOff
                      sx={{
                        color: "rgba(255, 255, 255, 0.9)",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        borderRadius: "50%",
                        padding: "4px",
                        fontSize: "30px",
                        position: "absolute",
                        top: "6px",
                        left: "6px",
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            )
        )}
      </Box>

      {photos.length === 0 && placeholderCount === 0 && (
        <Typography variant={"body1"} sx={{ marginBottom: "64px" }}>
          Add media to your empty folder.
        </Typography>
      )}

      {isEditing && (
        <Fab
          variant={"extended"}
          color={"primary"}
          sx={{
            display: "flex",
            float: "right",
            marginBottom: "8px",
            visibility: selectedIndexes.length > 0 ? "hidden" : "visible",
          }}
          onClick={() => {
            if (photoInputFile.current !== null) {
              photoInputFile.current.click()
            }
          }}
        >
          <AddPhotoAlternateOutlinedIcon sx={{ marginRight: "8px" }} />
          Add Media
        </Fab>
      )}

      <AlertSnackBar
        showAlert={warningAlertOpen}
        setShowAlert={setWarningAlertOpen}
        alertType={"warning"}
        alertText={FILE_UPLOAD_SIZE_ERROR}
        origin={"top"}
      />

      <input
        type="file"
        ref={photoInputFile}
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .gif, .mp4"
        onChange={(event) => {
          event.stopPropagation()
          event.preventDefault()
          const uploadFile = (i: number): void => {
            if (event.target.files !== null) {
              if (!fileSizeCheck(event.target.files, setWarningAlertOpen)) {
                return
              }
              setPlaceholderCount(event.target.files.length)
              const isVideo = event.target.files[i].type.match("video.*")
              const formData = new FormData()
              formData.append(
                isVideo ? "upload_file" : "image_file",
                event.target.files[i]
              )
              formData.append("profile", context.profileName)
              formData.append("is_public", currentFolder ? "false" : "true")
              formData.append("uploaded_from", "profile")
              if (currentFolder) {
                formData.append("folder_name", currentFolder.name)
              }
              const path = isVideo ? "photoset/create/video/" : "photo/upload/"
              post(`/photo_video/${path}`, formData, { timeout: 300000 })
                .then(() => {
                  i++
                  if (
                    event.target.files !== null &&
                    i < event.target.files.length
                  ) {
                    uploadFile(i)
                  } else {
                    getPhotos()
                    if (getProfilePhotos) {
                      getProfilePhotos()
                    }
                  }
                })
                .catch((error) => {
                  modalDisplayErrorHandler(
                    error.response.status,
                    errorDispatch,
                    error,
                    ProfileUpdate.Media,
                    () => setPlaceholderCount(0)
                  )
                })
            }
          }
          uploadFile(0)
        }}
        multiple
      />

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuFolder !== undefined)}
        onClose={() => {
          setMenuFolder(undefined)
        }}
        transitionDuration={menuFolder !== undefined ? "auto" : 0}
      >
        {isEditing && menuFolder !== null && (
          <MenuItem
            onClick={() => {
              setContextFolder(menuFolder)
              setOpenFolderNameDialog(true)
              setMenuFolder(undefined)
            }}
          >
            Rename
          </MenuItem>
        )}
        {isEditing && menuFolder !== null && (
          <MenuItem
            onClick={() => {
              setContextFolder(menuFolder)
              setOpenDeleteFolderDialog(true)
              setMenuFolder(undefined)
            }}
          >
            Delete
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            if (isEditing) {
              setIsSelecting(true)
              setMenuFolder(undefined)
              setSelectedIndexes(
                selectedIndexes.length === photos.length
                  ? []
                  : [...Array(photos.length).keys()]
              )
            } else {
              photos.forEach(
                (photo) =>
                  (photo.selected =
                    selectedPhotos.filter((photo) =>
                      photos.find((media) => media.id === photo.id)
                    ).length !== photos.length)
              )
              setMenuFolder(undefined)
              updatedSelectedPhotos()
            }
          }}
        >
          {selectedIndexes.length === photos.length ||
          selectedPhotos.filter((photo) =>
            photos.find((media) => media.id === photo.id)
          ).length === photos.length
            ? "Deselect All"
            : "Select All"}
        </MenuItem>
        {isEditing && (
          <MenuItem
            onClick={() => {
              if (isSelecting) {
                setIsSelecting(false)
                setSelectedIndexes([])
              } else {
                setIsSelecting(true)
              }
              setMenuFolder(undefined)
            }}
          >
            {isSelecting ? "Cancel Selection" : "Select Media"}
          </MenuItem>
        )}
      </Menu>

      <FolderNameDialog
        open={openFolderNameDialog}
        setOpen={setOpenFolderNameDialog}
        previousFolder={contextFolder}
        setPreviousFolder={setContextFolder}
        updateFolderName={(newFolderName) => {
          if (currentFolder) {
            currentFolder.name = newFolderName
          }
        }}
      />

      <DeleteFolderDialog
        open={openDeleteFolderDialog}
        setOpen={setOpenDeleteFolderDialog}
        folder={contextFolder}
        setPreviousFolder={setContextFolder}
        setActiveFolder={setActiveFolder}
      />

      <Box
        sx={{
          display: selectedIndexes.length > 0 ? "block" : "none",
          borderRadius: "16px 16px 0 0",
          backgroundColor: "#ffffff",
          bottom: 0,
          boxShadow: "0 0 32px #aaa",
          left: 0,
          position: "absolute",
          width: "100%",
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            justifyContent: "center",
            margin: "24px 16px",
          }}
        >
          <Typography
            variant={"body2"}
            color={"primary"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              if (currentFolder !== null) {
                moveSelected()
              } else {
                makeSelectedPrivate(false)
              }
            }}
          >
            {currentFolder === null ? "Make Public" : "Move to Public"}
          </Typography>
          {currentFolder === null && (
            <Typography
              variant={"body2"}
              color={"primary"}
              sx={{ cursor: "pointer" }}
              onClick={() => makeSelectedPrivate(true)}
            >
              Make Private
            </Typography>
          )}
          <Typography
            variant={"body2"}
            color={"primary"}
            sx={{ cursor: "pointer" }}
            onClick={() => openMoveDialog()}
          >
            Move
          </Typography>
          <Typography
            variant={"body2"}
            color={"error"}
            sx={{ cursor: "pointer" }}
            onClick={() => setDeleteModal(true)}
          >
            Delete ({selectedIndexes.length})
          </Typography>
        </Box>
      </Box>

      <ImageViewer
        onDelete={onMediaDelete}
        onUpdate={(message) => onMediaUpdate(message)}
        images={photos.map((photo) => {
          return photo
        })}
        open={imageViewerOpen}
        onClose={() => setImageViewerOpen(false)}
        position={currentImageIndex}
        setPosition={setCurrentImageIndex}
        isMediaProfile={true}
        snackbarMessage={snackbarMessage}
        setSnackbarMessage={setSnackbarMessage}
        handleMove={() => {
          setMoveFolderOpen(true)
          setImageViewerOpen(false)
        }}
        currentFolder={currentFolder}
      />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(snackbarMessage)}
        autoHideDuration={10000}
        onClose={() => {
          if (setSnackbarMessage) {
            setSnackbarMessage("")
          }
        }}
      >
        <Alert
          severity="success"
          onClose={() => {
            if (setSnackbarMessage) {
              setSnackbarMessage("")
            }
          }}
          sx={{
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Modal
        open={deleteModal}
        title="Delete Media"
        text="Are you sure you want to delete this media?"
        onHandle={deleteSelected}
        onClose={() => {
          setDeleteModal(false)
        }}
        optionOne="Cancel"
        optionTwo={`Delete Media (${selectedIndexes.length})`}
      />

      <MoveMediaDialog
        open={moveFolderOpen}
        setMoveFolderOpen={setMoveFolderOpen}
        handleMove={moveSelected}
        currectFolder={currentFolder}
        folders={folders}
      />
    </Box>
  )
}
