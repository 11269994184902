import styled from "@emotion/styled"
import { Photo } from "@mui/icons-material"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import {
  Alert,
  Portal,
  Snackbar,
  Theme,
  Typography,
  useTheme,
} from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { AddGreyedOut, EditGrayedOut } from "../Icons/GreyedOutIcons"
import { Colors } from "../../../styles/Colors"
import { get, post } from "../../../utils/requests"
import { UserContext } from "../../../contexts/user"
import { IPhoto } from "../../../utils/photos"
import { PopUp } from "../PopUp"
import { ImageViewer } from "../ImageViewer"
import { parsePhotos } from "../../../utils/parsers"
import useWindowDimensions, {
  getWindowDimensions,
} from "../../../utils/windowDimensions"
import { MediaThumbnails } from "./MediaThumbnails"
import {
  CHAT_MEDIA_MAX_FILES,
  FILE_UPLOAD_SIZE_ERROR,
} from "../../../utils/constants"
import {
  modalDisplayErrorHandler,
  ProfileUpdate,
} from "../../../utils/imageErrorModalHandler"
import { RequestErrorModal } from "../../Molecules/RequestErrorModal"
import { ErrorContext } from "../../../contexts/photoUploadErrors"
import { MediaContentType, MediaRequirement } from "./PromoRequirements"
import { BlossmImage } from "../BlossmImage"
import react from "react"
import { ManageMedia } from "../../Organisms/Profile/Media/ManageMedia"
import { IFolder } from "../../Organisms/Profile/Media/MediaFolders"

export interface IAddMediaProps {
  media?: IOrderPromoPhoto[]
  displayOnly?: boolean
  setModalOpen?: (open: boolean) => void
  set?: (photos: IOrderPromoPhoto[]) => void
  headless?: boolean
  openModal?: boolean
  uploadLocation?: string
  isPopUpOpen?: boolean
  hideIcon?: boolean
  hideMediaCount?: boolean
  maxMediaAmount?: number
  mediaRequirement?: MediaRequirement
  mediaContentType?: MediaContentType
}

export interface IOrderPromoPhoto extends IPhoto {
  selected: boolean
}

export const AddMedia = ({
  media,
  displayOnly = false,
  setModalOpen,
  set,
  headless = false,
  openModal = false,
  uploadLocation,
  isPopUpOpen = false,
  hideIcon = false,
  hideMediaCount = false,
  maxMediaAmount = CHAT_MEDIA_MAX_FILES,
  mediaRequirement,
  mediaContentType,
}: IAddMediaProps): react.JSX.Element => {
  const theme = useTheme()
  const { context } = useContext(UserContext)
  const [open, setOpen] = useState(isPopUpOpen)
  const [blossmPhotos, setBlossmPhotos] = useState<IOrderPromoPhoto[]>([])
  const [selectedPhotos, setSelectedPhotos] = useState<IOrderPromoPhoto[]>([])
  const photoInputFile = useRef<HTMLInputElement>(null)
  const [imageViewerOpen, setImageViewerOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [warningAlertOpen, setWarningAlertOpen] = useState<boolean>(false)
  const [warningAlertMessage, setWarningAlertMessage] = useState<string[]>([])
  const [imgCols, setImgCols] = useState(3)
  const [placeholderCount, setPlaceholderCount] = useState<number>(0)
  const { errorDispatch } = useContext(ErrorContext)
  const [folders, setFolders] = useState<IFolder[]>([])
  const [activeFolder, setActiveFolder] = useState<IFolder | undefined | null>(
    undefined
  )

  function getPhotos(): void {
    get(
      `/photo_video/photo/list/${context.profileName}/?include_recent_uploads=1`
    ).then((response) => {
      const newPhotos = parsePhotos(response.data)
      setBlossmPhotos(newPhotos)
    })
  }

  useEffect(() => {
    setSelectedPhotos((prevState) => [
      ...blossmPhotos.filter(
        (photo) => photo.selected && !prevState.includes(photo)
      ),
      ...prevState,
    ])
  }, [blossmPhotos])

  const gotPhotos = useRef(false)
  useEffect(() => {
    if (open && !gotPhotos.current) {
      getPhotos()
      gotPhotos.current = true
    }

    if (!open && openModal) {
      setOpen(true)
    }
  }, [open, openModal])

  useEffect(() => {
    if (headless && !media) {
      setOpen(true)
    }

    if (media) {
      media.forEach((file) => {
        const found = blossmPhotos.find((media) => media.id === file.id)

        if (found === undefined) {
          setBlossmPhotos((prevState) => [...prevState, file])
        }
      })
    }
  }, [media, headless])

  useEffect(() => {
    if (set && selectedPhotos.length > 0) {
      set(selectedPhotos)
    }
  }, [selectedPhotos])

  useEffect(() => {
    const { width } = getWindowDimensions()
    if (width > 640) {
      setImgCols(5)
    } else if (width > 480) {
      setImgCols(4)
    } else {
      setImgCols(3)
    }
  }, [useWindowDimensions()])

  useEffect(() => {
    if (selectedPhotos.length > maxMediaAmount) {
      setWarningAlertMessage([
        "Maximum of " + maxMediaAmount + " photos/videos can be selected!",
      ])
      setWarningAlertOpen(true)
      setSelectedPhotos((prevState) => prevState.slice(0, maxMediaAmount))
    }
  }, [selectedPhotos])

  function handleSave(): void {
    setOpen(false)
    if (set) {
      set(selectedPhotos)
    }

    if (setModalOpen) {
      setModalOpen(false)
    }
  }

  function openMediaUpload(): void {
    if (selectedPhotos.length === maxMediaAmount) {
      setWarningAlertMessage([
        "Maximum of " + maxMediaAmount + " photos/videos can be selected!",
      ])
      setWarningAlertOpen(true)
    } else if (photoInputFile.current !== null) {
      photoInputFile.current.click()
    }
  }

  function removedSelectedPhoto(photo: IOrderPromoPhoto): void {
    setSelectedPhotos((prevState) => [
      ...prevState.filter((media) => media.id !== photo.id),
    ])
    blossmPhotos.forEach((media) => {
      if (photo.id === media.id) {
        media.selected = false
      }
    })
  }

  function handleImageSelection(index: number): void {
    setCurrentImageIndex(index)
    setImageViewerOpen(true)
  }

  function fileCheck(files: FileList): boolean {
    let fileCount = false
    if (
      files !== null &&
      files.length + selectedPhotos.length > maxMediaAmount
    ) {
      fileCount = true
    }

    const fileGreaterThan200MB = Array.from(files).some((file) => {
      return file.size > 200 * 1024 * 1024 // Check if file is greater than 200 MB
    })

    if (fileGreaterThan200MB || fileCount) {
      const errorMessage = []
      if (fileGreaterThan200MB) {
        errorMessage.push(FILE_UPLOAD_SIZE_ERROR)
      }
      if (fileCount) {
        errorMessage.push(
          "Maximum of " + maxMediaAmount + " photos/videos can be selected!"
        )
      }
      setOpen(false)
      setWarningAlertMessage(errorMessage)
      setWarningAlertOpen(true)
      return false
    }
    return true
  }

  return (
    <>
      <input
        type="file"
        ref={photoInputFile}
        style={{ display: "none" }}
        accept="image/*, .jpg, .jpeg, .png, .gif, video/mp4,"
        onChange={(event) => {
          event.stopPropagation()
          event.preventDefault()

          if (event.target.files && !fileCheck(event.target.files)) {
            event.target.value = ""
            return
          }

          const uploadFile = (i: number): void => {
            if (event.target.files !== null) {
              setPlaceholderCount(event.target.files.length)
              const isVideo = event.target.files[i].type.match("video.*")
              const formData = new FormData()
              formData.append(
                isVideo ? "upload_file" : "image_file",
                event.target.files[i]
              )
              formData.append("profile", context.profileName)
              formData.append("is_public", "false")
              if (uploadLocation) {
                formData.append(
                  "uploaded_from",
                  activeFolder === undefined ? uploadLocation : "profile"
                )
              }
              if (activeFolder) {
                formData.append("folder_name", activeFolder.name)
              }
              const path = isVideo ? "photoset/create/video/" : "photo/upload/"
              post(`/photo_video/${path}`, formData, { timeout: 300000 })
                .then(() => {
                  i++
                  if (
                    event.target.files !== null &&
                    i < event.target.files.length
                  ) {
                    uploadFile(i)
                  } else {
                    get(
                      `/photo_video/photo/list/${context.profileName}/${
                        activeFolder ? activeFolder.name + "/" : ""
                      }?include_recent_uploads=1`
                    ).then((response) => {
                      const parsedPhotos = parsePhotos(response.data)
                      if (event.target.files !== null) {
                        for (let j = 0; j < event.target.files.length; j++) {
                          setBlossmPhotos((prevState) => {
                            return [
                              { ...parsedPhotos[j], selected: true },
                              ...prevState,
                            ]
                          })
                        }
                      }
                      setPlaceholderCount(0)
                      const prevActiveFolder = activeFolder
                      setActiveFolder(undefined)
                      setTimeout(() => {
                        setActiveFolder(prevActiveFolder)
                      }, 1)
                      if (photoInputFile.current) {
                        photoInputFile.current.value = ""
                      }
                    })
                  }
                })
                .catch((error) => {
                  modalDisplayErrorHandler(
                    error.response.status,
                    errorDispatch,
                    error,
                    ProfileUpdate.Media,
                    () => setPlaceholderCount(0)
                  )
                  if (photoInputFile.current) {
                    photoInputFile.current.value = ""
                  }
                })
            }
          }
          uploadFile(0)
        }}
        multiple
      />
      <PopUp
        open={open}
        handleToggle={(open) => {
          setOpen(open)
          handleSave()
        }}
      >
        <StyledMediaSelector theme={theme}>
          <ManageMedia
            profileName={context.profileName}
            folders={folders}
            setFolders={setFolders}
            setOpenManageMedia={handleSave}
            selectedPhotos={selectedPhotos}
            setSelectedPhotos={setSelectedPhotos}
            activeFolder={activeFolder}
            setActiveFolder={setActiveFolder}
            removedSelectedPhoto={removedSelectedPhoto}
            placeholderCount={placeholderCount}
            setPlaceholderCount={setPlaceholderCount}
            imgCols={imgCols}
            maxMediaAmount={maxMediaAmount}
            openMediaUpload={openMediaUpload}
            isEditing={false}
          />
        </StyledMediaSelector>
      </PopUp>
      <StyledAddMedia
        theme={theme}
        onClick={() => {
          if (!displayOnly) {
            setOpen(true)
          }
        }}
        displayOnly={displayOnly}
      >
        {selectedPhotos && (
          <ImageViewer
            images={selectedPhotos.map((photo) => {
              return photo
            })}
            open={imageViewerOpen}
            onClose={() => setImageViewerOpen(false)}
            position={currentImageIndex}
            setPosition={setCurrentImageIndex}
            showDownloadBtn
          />
        )}

        {headless ? (
          <MessagePreviewContainer>
            {selectedPhotos.map((image, index) => {
              return (
                <div
                  className={"image-container"}
                  key={image.id}
                  onClick={() => handleImageSelection(index)}
                >
                  <BlossmImage
                    scaleDown={true}
                    alt=""
                    src={`${
                      image.isVideo ? image.thumbnailUrl : image.originalUrl
                    }`}
                    borderRadius={6}
                  />
                  {image.isVideo && (
                    <PlayArrowIcon
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "#fff",
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "50%",
                        padding: "4px",
                        fontSize: "40px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              )
            })}
          </MessagePreviewContainer>
        ) : (
          // <MediaThumbnails media={selectedPhotos} onClick={handleImageSelection} />
          <>
            {!hideIcon && (
              <StyledIconBacking
                selected={selectedPhotos.length > 0}
                theme={theme}
              >
                <Photo sx={{ color: "inherit" }} fontSize={"small"} />
              </StyledIconBacking>
            )}
            <div className={"text-stack"}>
              {selectedPhotos?.length ? (
                <MediaThumbnails
                  media={selectedPhotos}
                  onClick={handleImageSelection}
                  displayOnly={displayOnly}
                />
              ) : (
                <Typography variant={"body1"}>{`Add ${
                  mediaContentType === MediaContentType.NonNude
                    ? "Non-Nude"
                    : ""
                } Photos/Videos`}</Typography>
              )}

              {!hideMediaCount && (
                <>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant={"body2"}
                  >
                    {selectedPhotos
                      ? selectedPhotos.length + " Attached Photos/Videos"
                      : "Media that you want the Seller to use"}
                  </Typography>
                  {selectedPhotos.length === 0 &&
                    mediaRequirement === MediaRequirement.Required && (
                      <Typography
                        color={theme.palette.error.main}
                        variant={"body2"}
                      >
                        (Required)
                      </Typography>
                    )}
                </>
              )}
            </div>
            {!displayOnly && (
              <div className={"add-icon"}>
                {selectedPhotos.length > 0 ? (
                  <EditGrayedOut />
                ) : (
                  <AddGreyedOut />
                )}
              </div>
            )}
          </>
        )}
      </StyledAddMedia>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={warningAlertOpen}
          autoHideDuration={10000}
          onClose={() => setWarningAlertOpen(false)}
        >
          <Alert severity="warning">
            {warningAlertMessage.map((message) => (
              <div key={message.split(" ")[0]}>{message}</div>
            ))}
          </Alert>
        </Snackbar>
      </Portal>
      <RequestErrorModal />
    </>
  )
}

const MessagePreviewContainer = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  overflow-x: auto;

  .image-container {
    border-radius: 6px;
    width: 100px;
    min-width: 100px;
    height: 100px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  }
`

const StyledMediaSelector = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .back-bar {
    display: flex;
    flex-direction: row;
    height: 80px;
    margin-left: 10px;
    margin-top: -15px;
    align-items: center;
  }

  .image-list-item {
    width: 112px;
    height: 112px;
    border-radius: 8px;
    cursor: pointer;
  }

  .image-list-item:hover {
    box-shadow: 0px 0px 5px 1px;
  }

  .image-list-item-selected {
    width: 112px;
    height: 112px;
    border: ${(props) => props.theme.palette.primary.main};
    border-radius: 4px;
    cursor: pointer;
  }

  .image-list-item-selected:hover {
    box-shadow: 0px 0px 5px 1px;
  }

  .image-radio {
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 0px;
  }

  .package-selector {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    gap: 110px;
    border-radius: 4px;

    .selector-label {
      font-size: 14px;
      font-weight: 500;
    }

    .selection-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`

export const StyledIconBacking = styled.div<{
  selected: boolean
  theme: Theme
}>`
  background: ${(props) =>
    props.selected ? props.theme.palette.primary.main : "none"};
  color: ${(props) =>
    !props.selected ? props.theme.palette.text.secondary : Colors.white};
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 10px;
`

const StyledAddMedia = styled.div<{ theme: Theme; displayOnly: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: ${(props) => (props.displayOnly ? "default" : "pointer")};

  .text-stack {
    display: flex;
    flex-direction: column;
  }

  .add-icon {
    margin-left: auto;
    cursor: pointer;
  }
`
