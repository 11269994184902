import { IProfileCardProps } from "../components/Organisms/HorizontalProfileCard"
import { ActionMap } from "../utils/types"
import React, { Dispatch } from "react"
import { ITag } from "../components/Atoms/OnBoarding/Tags"

export type platforms =
  | "All"
  | "OnlyFans"
  | "X/Twitter"
  | "Instagram"
  | "Facebook"
  | "TikTok"
  | "Reddit"
  | "Snapchat"
  | "Fansly"
  | "YouTube"
  | "ManyVids"
  | "Service Provider"
  | "Bluesky"

export type prices = "Any Price" | "Under $100" | "$100 - $500" | "$500 +"

export type followings =
  | "Any Following"
  | "Under 2K"
  | "2K - 10K"
  | "10K - 20K"
  | "20K + "

export type percentages = "Any OF %" | "OF Top 0.1%" | "OF Top 1%" | "OF Top 5%"

export type promoTypes =
  | "Assistant Services"
  | "Guaranteed Gains"
  | "Pinned Post"
  | "Repost"
  | "Story Share"
  | "Feed Post"
  | "Mass DM"
  | "Professional Services"
  | "Short"
  | "Friend Spot"
  | "Mention"
  | "Reel Post"
  | "Story Post"

export type filterType = {
  platform?: platforms
  price?: prices
  following?: followings
  percentage?: percentages
  promoTypes?: promoTypes[]
  tags?: ITag[]
  searchTerm?: string
}

export const defaultFilter: filterType = {
  platform: undefined,
  price: undefined,
  following: undefined,
  percentage: undefined,
  promoTypes: undefined,
  tags: undefined,
  searchTerm: undefined,
}

export const pagingInitialState: PagingStateType = {
  currentPage: 1,
  pageSize: 0,
  profileCards: [],
  numPages: 1,
  isMobile: false,
  appliedFilter: defaultFilter,
  resultsSynced: true,
}

export type PagingStateType = {
  currentPage: number
  pageSize: number
  profileCards: IProfileCardProps[]
  numPages: number
  isMobile: boolean
  appliedFilter: filterType
  resultsSynced: boolean
}

export enum PagingAction {
  InitializeResults = "InitializeResults",
  FilterByPlatform = "FilterByPlatform",
  FilterByPrice = "FilterByPrice",
  FilterByFollowing = "FilterByFollowing",
  FilterByPercentage = "FilterByPercentage",
  FilterByPromoType = "FilterByPromoType",
  FilterByTags = "FilterByTags",
  FilterBySearchTerm = "FilterBySearchTerm",
  ClearFilter = "ClearFilter",
  UpdateCards = "Update Cards",
  JumpToPage = "JumppToPage",
  FetchMore = "FetchMore",
  SetMobile = "Set Mobile",
}

type PagingPayload = {
  [PagingAction.InitializeResults]: {
    profileCards: IProfileCardProps[]
    pageSize: number
    numPages: number
    initialFilter: filterType
  }
  [PagingAction.FilterByPlatform]: {
    platform: platforms
  }
  [PagingAction.FilterByPrice]: {
    price: prices
  }
  [PagingAction.FilterByFollowing]: {
    following: followings
  }
  [PagingAction.FilterByPercentage]: {
    percentage: percentages
  }
  [PagingAction.FilterByPromoType]: {
    promoTypes: promoTypes[]
  }
  [PagingAction.FilterByTags]: {
    tags: ITag[]
  }
  [PagingAction.FilterBySearchTerm]: {
    term: string
  }
  [PagingAction.ClearFilter]: undefined
  [PagingAction.UpdateCards]: {
    profileCards: IProfileCardProps[]
    pageSize: number
    numPages: number
  }
  [PagingAction.JumpToPage]: {
    nextPage: number
  }
  [PagingAction.SetMobile]: boolean
  [PagingAction.FetchMore]: undefined
}

export type PagingActions =
  ActionMap<PagingPayload>[keyof ActionMap<PagingPayload>]

export function pagingReducer(
  state: PagingStateType = pagingInitialState,
  action: PagingActions
): PagingStateType {
  let newState
  let currentStateItem
  switch (action.type) {
    case PagingAction.SetMobile:
      newState = {
        ...state,
        isMobile: action.payload,
      }
      break
    case PagingAction.InitializeResults:
      newState = {
        ...state,
        profileCards: action.payload.profileCards,
        pageSize: action.payload.pageSize,
        numPages: action.payload.numPages,
        currentPage: 1,
        appliedFilter: action.payload.initialFilter,
        resultsSynced: true,
      }
      break
    case PagingAction.ClearFilter:
      newState = {
        ...state,
        appliedFilter: defaultFilter,
        profileCards: [],
        currentPage: 1,
        resultsSynced: false,
      }
      break
    case PagingAction.FetchMore:
      currentStateItem = state.currentPage
      newState = {
        ...state,
        currentPage: currentStateItem + 1,
        resultsSynced: false,
      }
      break
    case PagingAction.FilterByPlatform:
      newState = {
        ...state,
        appliedFilter: {
          ...state.appliedFilter,
          platform: action.payload.platform,
        },
        profileCards: [],
        currentPage: 1,
        resultsSynced: false,
      }
      break
    case PagingAction.FilterByPrice:
      newState = {
        ...state,
        appliedFilter: {
          ...state.appliedFilter,
          price: action.payload.price,
        },
        profileCards: [],
        currentPage: 1,
        resultsSynced: false,
      }
      break
    case PagingAction.FilterByFollowing:
      newState = {
        ...state,
        appliedFilter: {
          ...state.appliedFilter,
          following: action.payload.following,
        },
        profileCards: [],
        currentPage: 1,
        resultsSynced: false,
      }
      break
    case PagingAction.FilterByPercentage:
      newState = {
        ...state,
        appliedFilter: {
          ...state.appliedFilter,
          percentage: action.payload.percentage,
        },
        profileCards: [],
        currentPage: 1,
        resultsSynced: false,
      }
      break

    case PagingAction.FilterByPromoType:
      newState = {
        ...state,
        appliedFilter: {
          ...state.appliedFilter,
          promoTypes: action.payload.promoTypes,
        },
        profileCards: [],
        currentPage: 1,
        resultsSynced: false,
      }
      break

    case PagingAction.FilterByTags:
      newState = {
        ...state,
        appliedFilter: {
          ...state.appliedFilter,
          tags: action.payload.tags,
        },
        profileCards: [],
        currentPage: 1,
        resultsSynced: false,
      }
      break
    case PagingAction.FilterBySearchTerm:
      newState = {
        ...state,
        appliedFilter: {
          ...state.appliedFilter,
          searchTerm: action.payload.term,
        },
        profileCards: [],
        currentPage: 1,
        resultsSynced: false,
      }
      break
    case PagingAction.UpdateCards:
      if (state.isMobile) {
        currentStateItem = [
          ...state.profileCards,
          ...action.payload.profileCards,
        ]
      } else {
        currentStateItem = action.payload.profileCards
      }
      newState = {
        ...state,
        profileCards: currentStateItem,
        pageSize: action.payload.pageSize,
        numPages: action.payload.numPages,
        resultsSynced: true,
      }
      break
    case PagingAction.JumpToPage:
      newState = {
        ...state,
        currentPage: action.payload.nextPage,
        resultsSynced: false,
      }
      break
    default:
      newState = {
        ...state,
      }
      break
  }
  return newState
}

export const PagingContext = React.createContext<{
  pagingContext: PagingStateType
  pagingDispatch: Dispatch<PagingActions>
}>({
  pagingContext: pagingInitialState,
  pagingDispatch: () => null,
})
