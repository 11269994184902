import { Box, Fab, Menu, MenuItem } from "@mui/material"
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { get } from "../../../../utils/requests"
import { ArgJSONMap } from "../../../../utils/argjsonmap"
import { FolderNameDialog } from "./FolderNameDialog"
import { DeleteFolderDialog } from "./DeleteFolderDialog"
import { FolderItem } from "./FolderItem"

interface IMediaFoldersProps {
  profileName: string
  setActiveFolder: Dispatch<SetStateAction<IFolder | undefined | null>>
  folders: IFolder[]
  setFolders: Dispatch<SetStateAction<IFolder[]>>
  isEditing: boolean
}

export interface IFolder {
  name: string
  photoCount: number
  videoCount: number
  thumbnail?: string
}

export function parseFolders(rawFolders: object[]): IFolder[] {
  const photos = rawFolders.map((x: object) => {
    const folder = ArgJSONMap.fromParsedJson(x)
    return {
      name: folder.getString("name"),
      photoCount: folder.getNumber("photo_count"),
      videoCount: folder.getNumber("video_count"),
      thumbnail: folder.getStringOrUndefined("thumbnail"),
    }
  })
  return photos
}

export const MediaFolders = ({
  profileName,
  folders,
  setFolders,
  setActiveFolder,
  isEditing,
}: IMediaFoldersProps): JSX.Element => {
  const [openFolderNameDialog, setOpenFolderNameDialog] =
    useState<boolean>(false)
  const [openDeleteFolderDialog, setOpenDeleteFolderDialog] =
    useState<boolean>(false)
  const [menuFolder, setMenuFolder] = useState<IFolder | undefined | null>(
    undefined
  )
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | undefined>(
    undefined
  )
  const [contextFolder, setContextFolder] = useState<
    IFolder | undefined | null
  >(undefined)

  useEffect(() => {
    get("/photo_video/folder/")
      .then((response) => {
        setFolders(parseFolders(response.data))
      })
      .catch((error) => console.error(error))
  }, [profileName])

  return (
    <Box sx={{ padding: "8px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          margin: isEditing ? "8px 0 16px 0" : "0",
        }}
      >
        <FolderItem
          folder={null}
          index={-1}
          setActiveFolder={setActiveFolder}
        />
        <hr style={{ margin: "0", borderColor: "rgba(0, 0, 0, 0.12)" }} />
        {folders.map((folder, index) => (
          <FolderItem
            key={index}
            folder={folder}
            index={index}
            setMenuFolder={isEditing ? setMenuFolder : undefined}
            setMenuAnchor={isEditing ? setMenuAnchor : undefined}
            setActiveFolder={setActiveFolder}
          />
        ))}
      </Box>

      <FolderNameDialog
        open={openFolderNameDialog}
        setOpen={setOpenFolderNameDialog}
        setFolders={setFolders}
        previousFolder={contextFolder}
        setPreviousFolder={setContextFolder}
      />

      <DeleteFolderDialog
        open={openDeleteFolderDialog}
        setOpen={setOpenDeleteFolderDialog}
        setFolders={setFolders}
        folder={contextFolder}
        setPreviousFolder={setContextFolder}
        setActiveFolder={setActiveFolder}
      />

      {isEditing && (
        <Fab
          variant={"extended"}
          color={"primary"}
          sx={{
            display: "flex",
            float: "right",
            marginBottom: "8px",
            position: "sticky",
          }}
          onClick={() => {
            setOpenFolderNameDialog(true)
          }}
        >
          <CreateNewFolderOutlinedIcon sx={{ marginRight: "8px" }} />
          New Folder
        </Fab>
      )}

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuFolder)}
        onClose={() => {
          setMenuFolder(undefined)
        }}
        transitionDuration={menuFolder ? "auto" : 0}
      >
        <MenuItem
          onClick={() => {
            setContextFolder(menuFolder)
            setOpenFolderNameDialog(true)
            setMenuFolder(undefined)
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            setContextFolder(menuFolder)
            setOpenDeleteFolderDialog(true)
            setMenuFolder(undefined)
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Box>
  )
}
