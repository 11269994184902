import { Box, Dialog, TextField, Typography } from "@mui/material"
import { InverseButton, PrimaryButton } from "../../../Atoms/Buttons"
import { post, patch } from "../../../../utils/requests"
import { Dispatch, SetStateAction, useState } from "react"
import { theme } from "../../../../../styles/Theme"
import { IFolder, parseFolders } from "./MediaFolders"

export interface IFolderNameDialog {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setFolders?: Dispatch<SetStateAction<IFolder[]>>
  previousFolder: IFolder | undefined | null
  setPreviousFolder: Dispatch<SetStateAction<IFolder | undefined | null>>
  updateFolderName?: (newFolderName: string) => void
}

export const FolderNameDialog = ({
  open,
  setOpen,
  setFolders,
  previousFolder,
  setPreviousFolder,
  updateFolderName,
}: IFolderNameDialog): JSX.Element => {
  const [folderName, setFolderName] = useState<string>("")
  const [errorText, setErrorText] = useState<string>("")

  const createOrReanmeFolder = (): void => {
    if (folderName) {
      const request =
        previousFolder === undefined || previousFolder === null
          ? post("/photo_video/folder/", { name: folderName })
          : patch("/photo_video/folder/", {
              name: folderName,
              previousName: previousFolder.name,
            })

      request
        .then((response) => {
          handleClose()
          if (setFolders) {
            setFolders(parseFolders(response.data))
          }
          if (updateFolderName) {
            updateFolderName(folderName)
          }
        })
        .catch((error) => {
          if (error.response.data) {
            setErrorText(error.response.data)
          } else {
            console.error(error)
          }
        })
    }
  }

  const handleClose = (): void => {
    setOpen(false)
    setFolderName("")
    setErrorText("")
    setPreviousFolder(undefined)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          minWidth: "312px",
        },
      }}
    >
      <Typography variant="h6">
        {previousFolder ? "Rename" : "Create New"} Folder
      </Typography>
      <TextField
        name="folder-name"
        label="Folder Name"
        fullWidth={true}
        inputProps={{ maxLength: 50 }}
        variant="outlined"
        autoFocus={true}
        defaultValue={previousFolder ? previousFolder.name : ""}
        onChange={(e) => setFolderName(e.currentTarget.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setErrorText("")
            createOrReanmeFolder()
          }
        }}
      />
      {errorText && (
        <Typography variant="body1" color={theme.palette.error.main}>
          {errorText}
        </Typography>
      )}
      <Box sx={{ display: "flex", gap: "8px", justifyContent: "end" }}>
        <InverseButton variant={"outlined"} onClick={handleClose}>
          Cancel
        </InverseButton>
        <PrimaryButton
          onClick={() => {
            setErrorText("")
            createOrReanmeFolder()
          }}
          variant={"contained"}
          disabled={!folderName}
        >
          {previousFolder ? "Rename" : "Create"}
        </PrimaryButton>
      </Box>
    </Dialog>
  )
}
